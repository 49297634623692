import React from 'react';
import OtherSections from './OtherSections';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';
import { StickyPanelSidebar } from 'features/layout/panel/StickyPanelSidebar';
import useQueryString from 'hooks/useQueryString';

export const SearchSidebar = props => {
  const queryString = useQueryString();

  return (
    <StyledSearchSidebar
      className={props.className || ''}
      tabIndex={0}
      aria-label="Search Sidebar"
    >
      {queryString.query && !props.hideOthers ? (
        <OtherSections {...props} />
      ) : null}
      {props.children}
    </StyledSearchSidebar>
  );
};

export const StyledSearchSidebar = styled(StickyPanelSidebar)`
  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    max-width: 305px;
    min-width: 305px;
    border-right: 1px solid ${CEB_COLOR('ALTO')};
    overflow: auto;
    height: 100%;
  }

  background: rgb(240, 238, 235);
  &:focus {
    outline: 0;
  }
`;

export default SearchSidebar;
