import React from 'react';
import { FilterOption } from 'components/practitioner/FilterOption';
import { useFilters } from './useFilters';
import { GET_SPECIAL_REQUIREMENTS_CATEGORIES } from './GET_SPECIAL_REQUIREMENTS_CATEGORIES';
import { GET_SPECIALIZATION_CREDIT_CATEGORIES } from './GET_SPECIALIZATION_CREDIT_CATEGORIES';
import { DateFilters } from './DateFilters';
import { StyledExpandableSection } from './StyledExpandableSection';
import { GET_PRACTICE_AREA_WITH_COURSE } from './GET_PRACTICE_AREA_WITH_COURSE';
import { CLELibraryPracticeAreaFilters } from './CLELibraryPracticeAreaFilters';

export function CLELibraryCourseFilters({ className, userPracticeAreas }) {
  const [
    specialRequirementsFilters,
    specialRequirements,
    onOptionSpecialRequirementsChange,
  ] = useFilters(
    GET_SPECIAL_REQUIREMENTS_CATEGORIES,
    'specialRequirements',
    data =>
      data.contentfulCourseCreditCategoryCollection.items[0]
        .childCategoriesCollection.items,
  );

  const [
    specializationCreditsFilters,
    specializationCredits,
    onOptionSpecializationCreditsChange,
  ] = useFilters(
    GET_SPECIALIZATION_CREDIT_CATEGORIES,
    'specializationCredits',
    data => data.contentfulCourseCreditCategoryCollection.items,
  );

  return (
    <div className={className}>
      <CLELibraryPracticeAreaFilters
        query={GET_PRACTICE_AREA_WITH_COURSE}
        linkedFrom="courseCollection"
        userPracticeAreas={userPracticeAreas}
      />
      <StyledExpandableSection
        name="Year Recorded"
        border={false}
        defaultExpanded
        cache
      >
        <DateFilters />
      </StyledExpandableSection>
      <StyledExpandableSection
        name="Special Requirements"
        border={false}
        defaultExpanded
        cache
      >
        {specialRequirementsFilters.map(item => (
          <FilterOption
            key={item.sys.id}
            filter={{
              filterValue: item.sys.id,
              name: item.name,
              checked: specialRequirements[item.sys.id] === true,
            }}
            onOptionChange={onOptionSpecialRequirementsChange}
          />
        ))}
      </StyledExpandableSection>
      <StyledExpandableSection
        name="Specialization Credit"
        border={false}
        defaultExpanded
        cache
      >
        {specializationCreditsFilters
          .filter(item => item.childCategoriesCollection.items.length > 0)
          .map(item => (
            <FilterOption
              key={item.sys.id}
              filter={{
                filterValue: item.sys.id,
                name: item.name,
                checked: specializationCredits[item.sys.id] === true,
              }}
              onOptionChange={onOptionSpecializationCreditsChange}
            />
          ))}
      </StyledExpandableSection>
    </div>
  );
}
