import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useWindowSize from 'hooks/useWindowSize';
import ReactDOM from 'react-dom';

interface RelativeTargetProps extends Pick<HTMLElement, 'tabIndex'> {
  target: React.RefObject<HTMLElement>;
  children?: React.ReactNode;
  hAnchor?: 'left' | 'right' | 'center';
  vAnchor?: 'top' | 'bottom' | 'middle';
  zIndex?: number;
  style?: React.CSSProperties;
  [key: string]: any;
}

export const RelativeTarget = React.forwardRef<
  HTMLDivElement,
  RelativeTargetProps
>(
  (
    {
      target,
      children,
      hAnchor = 'left',
      vAnchor = 'top',
      zIndex = 110,
      style,
      ...props
    },
    ref,
  ) => {
    const windowSize = useWindowSize();
    const [targetUpdate, setTargetUpdate] = useState(0);
    const isFirstRender = useRef(true);
    const previousRect = useRef<DOMRect | null>(null);

    useEffect(() => {
      if (
        props?.tabIndex === 0 &&
        (ref as MutableRefObject<HTMLDivElement | null>)?.current
      ) {
        (ref as MutableRefObject<HTMLDivElement | null>).current?.focus();
      }
    }, []);

    const checkForChanges = useCallback(() => {
      if (target?.current) {
        const currentRect = target.current.getBoundingClientRect();
        if (
          !previousRect.current ||
          !isRectEqual(previousRect.current, currentRect)
        ) {
          previousRect.current = currentRect;
          setTargetUpdate(prev => prev + 1);
        }
        requestAnimationFrame(checkForChanges);
      }
    }, [target]);

    useEffect(() => {
      if (!target.current) return;

      const resizeObserver = new ResizeObserver(() => {
        setTargetUpdate(prev => prev + 1);
      });

      if (isFirstRender.current) {
        isFirstRender.current = false;
        requestAnimationFrame(() => {
          if (target.current) {
            resizeObserver.observe(target.current!);
            checkForChanges();
          }
        });
      } else {
        if (target.current) {
          resizeObserver.observe(target.current);
          checkForChanges();
        }
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, [target, checkForChanges]);

    const { left, top, width } = useMemo(() => {
      const boundingClientRect = target.current?.getBoundingClientRect();
      if (!boundingClientRect) return { left: 0, top: 0, width: 0 };

      const { x, y, width, height } = boundingClientRect;
      const scrollX = window.scrollX;
      const scrollY = window.scrollY;

      let leftPosition = x;
      if (hAnchor === 'right') {
        leftPosition = x + width;
      } else if (hAnchor === 'center') {
        leftPosition = x + width / 2;
      }

      let topPosition = y;
      if (vAnchor === 'bottom') {
        topPosition = y + height;
      } else if (vAnchor === 'middle') {
        topPosition = y + height / 2;
      }

      return {
        left: leftPosition + scrollX,
        top: topPosition + scrollY,
        width,
      };
    }, [target, hAnchor, vAnchor, windowSize, targetUpdate]);

    return ReactDOM.createPortal(
      <div
        style={{
          position: 'absolute',
          left,
          top,
          zIndex,
          ...(style || {}),
        }}
        ref={ref}
        {...props}
      >
        {children}
      </div>,
      document.body,
    );
  },
);

RelativeTarget.displayName = 'RelativeTarget';

function isRectEqual(rect1: DOMRect, rect2: DOMRect): boolean {
  return (
    rect1.top === rect2.top &&
    rect1.right === rect2.right &&
    rect1.bottom === rect2.bottom &&
    rect1.left === rect2.left
  );
}
