import React, { useLayoutEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { layoutScrolledStateAtom } from '.';

interface StickyPanelSidebarProps {
  children: React.ReactNode;
  className?: string;
}

export function StickyPanelSidebar({
  children,
  className,
}: StickyPanelSidebarProps) {
  const { scrolled } = useRecoilValue(layoutScrolledStateAtom);
  const elRef = useRef<HTMLDivElement>(null);
  const sidebarHeight = useRef(0);

  useLayoutEffect(() => {
    // Reset scroll position when sidebar changes to fixed/sticky
    if (elRef.current && scrolled) {
      elRef.current.scrollTop = 0;
    }
  }, [scrolled]);

  sidebarHeight.current = elRef.current?.clientHeight ?? 0;

  return (
    <>
      <div style={{ position: 'relative' }}>
        <StyledStickyPanelSidebar
          $fixed={scrolled}
          className={className}
          ref={elRef}
        >
          {children}
        </StyledStickyPanelSidebar>
        <StyledSidebarSpacer style={{ height: sidebarHeight.current }} />
      </div>
    </>
  );
}

const StyledSidebarSpacer = styled.div`
  position: relative;
  visibility: hidden;
`;

const StyledStickyPanelSidebar = styled.div<{ $fixed: boolean }>`
  @media screen and (min-width: 1164px) {
    position: absolute;
    ${({ $fixed }) =>
      $fixed &&
      css`
        position: fixed;
        top: 0;
        height: 100%;
        overflow: auto;
      `}
  }
`;
