import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { CourseCardProgress } from './CourseCard/CourseCardProgress';
import { CourseCardBody } from './CourseCard/CourseCardBody';
import { CourseCardBodyTitle } from './CourseCard/CourseCardBodyTitle';
import { CourseCardImage } from './CourseCard/CourseCardImage';
import { GetCourseSearchQuery } from '__generated__/graphql';

const BP_HH = '1166px';

type Course = NonNullable<
  NonNullable<GetCourseSearchQuery['search']>['edges']
>[number];

export interface CLELibrarySearchResultProps {
  data?: Course;
  rootSlugs: string[];
  className?: string;
  path: string;
  courseSearchBase: string;
  collection?: any[];
  breakpoint?: string;
  referrer?: string;
  watchlistable?: boolean;
  watchlistRemovable?: boolean;
  onRemoveFromWatchlist?: () => void;
  summaryLength?: number;
}

export function CLELibrarySearchResult({
  data,
  rootSlugs,
  className,
  path,
  courseSearchBase,
  collection,
  breakpoint,
  referrer,
  watchlistable = false,
  watchlistRemovable = false,
  onRemoveFromWatchlist,
  summaryLength = 200,
}: CLELibrarySearchResultProps) {
  return (
    <StyledSearchResult
      className={className}
      // hasQueue={false}
      breakpoint={breakpoint}
    >
      <section>
        <CourseCardImage course={data} path={path} />
        <section className="card-body">
          <CourseCardBodyTitle
            course={data}
            path={path}
            referrer={referrer}
            rootSlugs={rootSlugs}
            courseSearchBase={courseSearchBase}
            watchlistable={watchlistable}
            watchlistRemovable={watchlistRemovable}
            onRemoveFromWatchlist={onRemoveFromWatchlist}
          />
          <CourseCardBody course={data} summaryLength={summaryLength} />
        </section>
      </section>
      <CourseCardProgress
        course={data}
        collection={collection}
        referrer={referrer}
      />
    </StyledSearchResult>
  );
}

interface StyledSearchResultProps {
  breakpoint?: string;
}

const StyledSearchResult = styled.li<StyledSearchResultProps>`
  border-radius: 4px;
  border: solid 1px rgba(0, 52, 117, 0.15);
  display: block;
  margin: 24px 0 24px 0;
  position: relative;
  max-width: 992px;
  @media screen and (min-width: ${({ breakpoint = BP_HH }) => breakpoint}) {
    > section {
      display: flex;
    }
  }

  .card-image {
    margin: 15px;
    @media screen and (min-width: ${({ breakpoint = BP_HH }) => breakpoint}) {
      flex: 0 1 auto;
      max-width: 232px;
      margin: 15px 0 15px 14px;
    }
  }

  .card-body {
    margin: 0 14px 14px 14px;
    @media screen and (min-width: ${({ breakpoint = BP_HH }) => breakpoint}) {
      flex: 1 1 auto;
      max-width: calc(100% - 232px);
      margin-bottom: 15px;
      margin-right: 14px;
      margin-left: 18px;
    }

    .including-message {
      font-size: 15px;
      margin-right: 13px;
    }
  }

  .card-body-title {
    display: block;
    @media screen and (min-width: ${({ breakpoint = BP_HH }) => breakpoint}) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .card-practice-areas {
    button {
      margin-top: 12px;
    }
    @media screen and (min-width: ${({ breakpoint = BP_HH }) => breakpoint}) {
      display: flex;
      text-align: right;
      margin-top: -6px;
      ul {
        margin-left: auto;
      }
      button {
        margin-left: 0.5em;
      }
    }
  }

  .card-body-completed {
    margin-top: 12px;
    > section {
      margin: 0 0 16px 0;
    }
    a {
      padding: 6px 20px;
    }
    .tooltip-target {
      display: inline-block;
    }
  }

  .card-body-queue-status {
    position: absolute;
    right: 10px;
    top: 9px;
    font-size: 16px;
    &.current {
      color: ${CEB_COLOR('TEAL')};
    }
  }

  .card-body-completed-date,
  .card-body-recorded-date {
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 2px;
    margin-top: 0;
  }

  .card-body-summary {
    font-family: 'Zilla Slab', serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 7px;
  }

  .card-progress {
    margin: -10px 14px 12px 14px;
    display: block;
    @media screen and (min-width: ${({ breakpoint = BP_HH }) => breakpoint}) {
      display: flex;
      section {
        &.course-progress {
          max-width: 40%;
        }
        &:first-child {
          padding-top: 0;
          min-width: 232px;
          max-width: 232px;
        }
        + section {
          margin-left: 18px;
        }
      }
    }
  }
`;
