import React from 'react';
import styled from 'styled-components';
import Loading from 'components/common/Loading';
import { CLELibrarySearchResultsWithProgress } from 'components/cle-library/landing/CLELibrarySearchResults';
import { CLELibrarySearchResult } from 'components/cle-library/landing/CLELibrarySearchResult';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { CLELibraryCourseFilters } from 'components/cle-library/landing/CLELibraryCourseFilters';
import { StyledFilterSectionTitleWithBorder } from 'components/common/search/sidebar/StyledFilterSectionTitle';
import { usePracticeAreaRootSlugs } from 'components/cle-library/usePracticeAreaRootSlugs';
import { useUserPracticeAreas } from 'components/daily-news/useUserPracticeAreas';
import TitledPage from 'components/common/TitledPage';
import courseUrl from 'util/courseUrl';
import { StyleConstant } from 'util/getStyleConstant';
import { PanelLayout } from 'features/layout/panel';
import { StickyPanelSidebar } from 'features/layout/panel/StickyPanelSidebar';
import { StyledCourseSearchSidebar } from './StyledCourseSearchSidebar';

export default function LearningCLECourses() {
  const rootSlugs = usePracticeAreaRootSlugs();
  const { userPracticeAreas } = useUserPracticeAreas();

  return (
    <TitledPage title="CLE Courses">
      <PanelLayout
        sidebarKey="course-catalog-sidebar"
        sidebar={
          <StickyPanelSidebar>
            <StyledCourseSearchSidebar type="COURSE" hideOthers>
              <StyledFilterSectionTitleWithBorder>
                Filter Results
              </StyledFilterSectionTitleWithBorder>
              <CLELibraryCourseFilters userPracticeAreas={userPracticeAreas} />
            </StyledCourseSearchSidebar>
          </StickyPanelSidebar>
        }
      >
        <StyledContentSection>
          <Loading>
            <CLELibrarySearchResultsWithProgress
              userPracticeAreas={userPracticeAreas}
            >
              {result => (
                <StyledCLELibrarySearchResult
                  key={`course-result-${result.contentfulId}`}
                  rootSlugs={rootSlugs}
                  data={result}
                  path={courseUrl(result.slug)}
                  breakpoint={StyleConstant.BREAKPOINT_HANDHELD}
                  watchlistable
                />
              )}
            </CLELibrarySearchResultsWithProgress>
          </Loading>
        </StyledContentSection>
      </PanelLayout>
    </TitledPage>
  );
}

export const StyledCLELibrarySearchResult = styled(CLELibrarySearchResult)`
  .card-body-title h3 {
    font-family: 'Zilla Slab', serif;
    font-weight: bold;
    font-size: 17px;
  }
  .card-body-recorded-date {
    color: ${CEB_COLOR('BLACK')};
    font-family: 'Zilla Slab', serif;
    font-style: normal;
    margin-top: 6px;
    margin-bottom: 6px;
    time {
      font-weight: bold;
    }
  }
`;

const StyledContentSection = styled.section`
  padding-top: 20px;
  padding-bottom: 20px;
`;
