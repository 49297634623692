import React from 'react';
import styled, { css } from 'styled-components';
import { LayoutFooter } from 'features/layout/twentyfour/footer';
import {
  atom,
  atomFamily,
  useRecoilState_TRANSITION_SUPPORT_UNSTABLE,
  useSetRecoilState,
} from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { PanelExpand, PanelRetract } from './PanelIcon';
import { RelativeTarget } from 'components/common/RelativeTarget';
import { useInView } from 'react-intersection-observer';

interface LayoutScrolledState {
  scrolled: boolean;
}

export const layoutScrolledStateAtom = atom<LayoutScrolledState>({
  key: 'LayoutScrolledState',
  default: { scrolled: false },
});

const { persistAtom } = recoilPersist();

export type PanelSidebarState = 'open' | 'closed';

export const PanelSidebarStateAtom = atomFamily<PanelSidebarState, string>({
  key: 'PanelSidebarState',
  default: 'open',
  effects_UNSTABLE: [persistAtom],
});

interface PanelLayoutProps {
  children: React.ReactNode;
  sidebar: React.ReactNode;
  sidebarKey: string;
  sidebarWidth?: number;
  collapsible?: boolean;
  className?: string;
  topOffset?: number;
}

export const PanelLayoutContext = React.createContext<{
  sidebarKey: string;
  sidebarWidth: number;
  collapsible: boolean;
  sidebarClosed: boolean;
  mainPanelRef: React.RefObject<HTMLElement>;
}>({
  sidebarKey: '',
  sidebarWidth: 360,
  collapsible: true,
  sidebarClosed: false,
  mainPanelRef: { current: null },
});

export function PanelLayout({
  children,
  sidebar,
  sidebarKey = '',
  sidebarWidth = 360,
  collapsible = true,
  className,
  topOffset = 55,
}: PanelLayoutProps) {
  const panelRef = React.useRef<HTMLDivElement>(null);

  const [sidebarState, setSidebarState] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(
      PanelSidebarStateAtom(sidebarKey),
    );

  const handleButtonClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setSidebarState(sidebarState === 'open' ? 'closed' : 'open');
    },
    [sidebarState, setSidebarState],
  );

  const buttonIconRef = React.useRef<HTMLSpanElement>(null);
  const mainPanelRef = React.useRef<HTMLDivElement>(null);
  const { ref: scrolledDetectorRef, inView } = useInView({
    threshold: 0.05,
  });
  const setLayoutScrolled = useSetRecoilState(layoutScrolledStateAtom);

  // Update layoutScrolled state when inView changes
  React.useEffect(() => {
    setLayoutScrolled({ scrolled: !inView });
  }, [inView, setLayoutScrolled]);

  const [showTooltip, setShowTooltip] = React.useState(false);
  const sidebarClosed = collapsible && sidebarState === 'closed';

  const [$topOffset, setTopOffset] = React.useState(0);

  const calculateTopOffset = React.useCallback(
    (el: HTMLElement) => {
      setTopOffset(topOffset + (el?.getBoundingClientRect().top ?? 0));
    },
    [topOffset],
  );

  return (
    <PanelLayoutContext.Provider
      value={{
        sidebarKey,
        sidebarWidth,
        collapsible,
        sidebarClosed,
        mainPanelRef,
      }}
    >
      <div ref={scrolledDetectorRef} />
      <StyledPanelLayout className={className || ''} ref={calculateTopOffset}>
        <StyledMainPanelContainer className="panel-main-container">
          <StyledMainPanel
            $sidebarClosed={sidebarClosed}
            $sidebarWidth={sidebarWidth}
            className="panel-main-panel"
            ref={mainPanelRef}
          >
            {children}
          </StyledMainPanel>
          <StyledLayoutFooter
            className="panel-layout-footer"
            $sidebarClosed={sidebarClosed}
          />
        </StyledMainPanelContainer>
        <StyledLayoutFooter
          className="panel-layout-footer"
          $sidebarClosed={sidebarClosed}
        />
        <StyledSidebarPanel
          $sidebarClosed={sidebarClosed}
          $sidebarWidth={sidebarWidth}
          className="panel-sidebar"
        >
          {sidebar}
          {collapsible && (
            <StyledSidebarPanelToggleButton
              className="panel-button-toggle"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={handleButtonClick}
              $sidebarClosed={sidebarClosed}
              $sidebarWidth={sidebarWidth}
              $topOffset={$topOffset}
            >
              <span ref={buttonIconRef}>
                <PanelExpand />

                <RelativeTarget
                  target={buttonIconRef}
                  hAnchor="right"
                  vAnchor="middle"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: 0,
                  }}
                >
                  {showTooltip && sidebarState === 'closed' && (
                    <StyledPanelButtonTooltip className="panel-button-tooltip">
                      Restore Sidebar
                    </StyledPanelButtonTooltip>
                  )}
                </RelativeTarget>
              </span>
            </StyledSidebarPanelToggleButton>
          )}
        </StyledSidebarPanel>
      </StyledPanelLayout>
    </PanelLayoutContext.Provider>
  );
}

const StyledPanelLayout = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas: 'panel-sidebar' 'panel-main' 'panel-footer';
  height: 100%;
  > .panel-layout-footer {
    display: flex;
    grid-area: panel-footer;
  }
  > .panel-sidebar {
    grid-area: panel-sidebar;
  }

  @media screen and (min-width: 1164px) {
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'panel-sidebar' 'panel-main';
    > .panel-layout-footer {
      display: none;
    }
  }
`;

const StyledSidebarPanel = styled.div<{
  $sidebarClosed: boolean;
  $sidebarWidth: number;
}>`
  position: relative;
  @media screen and (min-width: 1164px) {
    ${({ $sidebarClosed, $sidebarWidth }) =>
      $sidebarClosed
        ? css`
            width: 0;
            > *:first-child {
              display: none;
            }
          `
        : css`
            width: ${$sidebarWidth}px;
          `}
  }
`;

const StyledSidebarPanelToggleButton = styled.button<{
  $sidebarClosed: boolean;
  $sidebarWidth: number;
  $topOffset: number;
}>`
  display: none;

  @media screen and (min-width: 1164px) {
    display: flex;
    justify-content: center;
    left: calc(${({ $sidebarWidth }) => $sidebarWidth}px - 36px / 2);
    position: fixed;
    top: ${({ $topOffset }) => $topOffset}px;
    right: auto;
    ${({ $sidebarClosed, $topOffset }) =>
      $sidebarClosed &&
      css`
        left: 42px;
        height: calc(100vh - 144px * 2);
        position: fixed;
        top: ${$topOffset}px;
        &:after {
          content: '';
          height: 96px;
          width: 1px;
          background: linear-gradient(
            to bottom,
            var(--COLOR_VERY_LIGHT_PINK),
            #c8c8c8 49%,
            var(--COLOR_VERY_LIGHT_PINK)
          );
          position: absolute;
          top: -25px;
          z-index: -1;
          left: 18px;
        }
      `}
  }
  span {
    box-sizing: border-box;
    padding: 11px 7px 10px 7px;
    border-radius: 18px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #e4e4e4;
    background-color: white;
    width: 36px;
    height: 36px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: ${({ $sidebarClosed }) =>
      $sidebarClosed ? 'scaleX(-1)' : 'none'};
  }
  i {
    position: relative;
    box-sizing: border-box;
    font-size: 15px;
    color: var(--COLOR_BLUEY_GRAY);
    + i {
      margin-left: 4px;
    }
  }
  &:focus {
    outline: none;
    span {
      outline: 1px solid
        color-mix(in srgb, var(--COLOR_NICE_BLUE) 5%, transparent);
      box-shadow: 0 2px 12px 0
        color-mix(in srgb, var(--COLOR_NICE_BLUE) 5%, rgba(0, 0, 0, 0.05));
    }
  }
`;

const StyledPanelButtonTooltip = styled.div`
  background-color: white;
  border: solid 1px var(--COLOR_MERCURY);
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  color: var(--COLOR_COOL_GRAY);
  font-family: var(--FONT_SANS_SERIF);
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  margin-left: 5px;
  padding: 11px 9px 9px 16px;
  position: relative;
  &:before {
    content: '';
    border: 5px solid transparent;
    border-right-color: white;
    display: block;
    left: -10px;
    position: absolute;
    top: calc(50% - 5px);
  }
`;

const StyledMainPanelContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr min-content;
  min-height: 100%;
  .panel-layout-footer {
    display: none;
  }
  @media screen and (min-width: 1164px) {
    .panel-layout-footer {
      display: flex;
    }
  }
`;

const StyledMainPanel = styled.div<{
  $sidebarClosed: boolean;
  $sidebarWidth: number;
}>`
  background: white;
  padding: 0;
  border-top-left-radius: 8px;
  box-shadow: 0 6px 50px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  margin-bottom: 96px;
  ${({ $sidebarWidth, $sidebarClosed }) =>
    $sidebarClosed
      ? css`
          min-width: 960px;
        `
      : css`
          min-width: ${1164 - $sidebarWidth}px;
        `}
  ${({ $sidebarClosed }) =>
    $sidebarClosed &&
    css`
      @media screen and (min-width: 980px) {
        border-radius: 8px;
        margin: 0 auto 96px;
        max-width: 1080px;
      }
    `}
`;

const StyledLayoutFooter = styled(LayoutFooter)<{ $sidebarClosed: boolean }>`
  ${({ $sidebarClosed }) =>
    !$sidebarClosed &&
    css`
      @media screen and (min-width: 1164px) {
        border-top-left-radius: 8px;
        border: solid 1px var(--COLOR_QUILL_GRAY);
        border-right: none;
        border-bottom: none;
      }
    `}
  display: none;
`;

export const StyledMainPanelContent = styled.div`
  max-width: 1164px;
  box-sizing: border-box;
  padding: 28px 30px 48px;
`;
